<template>
    <app-transition>
        <Dialog
            class="WithdrawalFormError"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                    <div class="WithdrawalFormError-title">
                        <slot name="title"/>
                    </div>
                    <div class="WithdrawalFormError-description">
                        <slot name="description"/>
                    </div>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "WithdrawalFormErrorDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    components: {
        Dialog,
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.WithdrawalFormError {
    &-title {
        font-size: 24px;
        font-style: italic;
        width: 100%;
        margin-bottom: 6px;
    }
    &-description {
        font-size: 18px;
        font-style: italic;
        width: 100%;
    }
    &-btn.v-btn {
        border-radius: 8px;
        padding: 13px 53px;

        span {
            line-height: 19px;
        }
    }
}
</style>
